import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { ArrowLeftCircleFill, ShareFill } from 'react-bootstrap-icons';
import DetailProfile from '../../section/DetailProfile';
import DetailCircle from '../../section/DetailCircle';
import About from '../../section/About';
import JoinAdvisor from '../../section/JoinAdvisor';
import Certification from '../../section/Certification';
import ClientSwiper from '../../carousel/ClientSwiper';
import JoinTeam from '../../section/JoinTeam';
import ShareModal from '../../section/ShareModal';
import useMicroSiteEncrypt from '../../CustomHook/useMicroSiteEncrypt';
import CommonCarousel from '../../carousel/CommonCarousel';
import RecaptchaComponent from '../../common/RecaptchaComponent';
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';
import { eventTracking, fetchLeaderDetailData } from '../../../redux/actions/micrositeAction';

const LeaderContainer = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let intervalCount = null;
    const location = useLocation();
    const idleTimerRef = useRef(null);
    const queryParams = new URLSearchParams(location.search);
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const { microSiteEncrypt } = useMicroSiteEncrypt();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const url_data = useSelector((state) => state.microsite?.url_data);
    const profile_data = useSelector((state) => state.microsite?.profile_data);
    const profile_type = useSelector((state) => state.microsite?.profile_data?.profile_type);
    const [timeoutId, setTimeoutId] = useState(null); // State to track timeout ID
    const [hasCalledApi, setHasCalledApi] = useState(false); // State to track if API call has been made
    const captcha_key =
    window.location.origin.includes("spdevmfp") ||
    window.location.origin.includes("localhost")
      ? "6LfunNgbAAAAAEM0X3hcwSBYKJwIqHmmInfz0uFK"
      : window.location.origin.includes("sppreprod") ||
        window.location.origin.includes("sptestmfp")
      ? "6Ldeg6YaAAAAAAZqU7AQ_j-lG4LcnwP4jfNFiCJJ"
      : "6Lf9fqoaAAAAAH8JhnTg9gLI3KsYKmR2tdCRVg8i";
    const handleClose = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        const startTracking = async () => {
            const tokenLeader = await microSiteEncrypt();
            if (tokenLeader && tokenLeader !== "") {
                dispatch(fetchLeaderDetailData(tokenLeader, url_data));
            }

            document.title = `Microsite: ${profile_data?.microsite?.name} | TATA AIA Partner`;
            setTimeout(() => {
                generateEventAction("start");
            }, 2000);
        };

        startTracking();
        return () => {
            generateEventAction("end");
        };
    }, []);

    const generateEventAction = async (action) => {
        let tokenEvent = "";
        let cookie_s_t = Cookies.get("s_t");
        let url = window?.location?.href?.replace("/site", "");
        if (!url?.includes("profile") && url?.includes(".com")) {
            url = url?.replace(".com", ".com/profile");
        } else if(!url?.includes("profile") && url?.includes(".in")) {
            url = url?.replace(".in", ".in/profile");
        } else {
            console.log(url);
        }
        let flow = "profile";
        let contact_id = Cookies.get("c") || "";
        let recaptcha = await getRecaptchaToken();
        let start_time = cookie_s_t ? cookie_s_t : new Date();
        let end_time = new Date();
        tokenEvent = await microSiteEncrypt();
        if (!Cookies.get("vtkn")) {
           Cookies.set("vtkn", tokenEvent); 
        }
        if (tokenEvent && tokenEvent !== "") {
            dispatch(eventTracking(tokenEvent, recaptcha, url, action, (action === "end" || action === "idle") ? formatDateToISD(Cookies.get("s_t")) : formatDateToISD(start_time), (action === "end" || action === "idle") ? formatDateToISD(end_time) : "", flow, contact_id));
        }
    };
    
    function formatDateToISD(dateString) {
        const date = new Date(dateString);

        // Helper function to add leading zero
        const padZero = (num) => (num < 10 ? `0${num}` : num);

        const year = date.getFullYear();
        const month = padZero(date.getMonth() + 1); // Months are zero-indexed
        const day = padZero(date.getDate());
        const hours = padZero(date.getHours());
        const minutes = padZero(date.getMinutes());
        const seconds = padZero(date.getSeconds());

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const handleOnIdle = () => {
        if (!hasCalledApi) {
            console.log("User is idle. Setting up timeout for API call.");

            // Set a timeout to trigger the API call after 10 minutes
            const id = setTimeout(() => {
                console.log("Making API call after 10 minutes of being idle.");
                generateEventAction("idle");
                setHasCalledApi(true); // Mark that API call has been made
            }, 10 * 60 * 1000);

            setTimeoutId(id);
        }
    };
    
    const getRecaptchaToken = async () => {
        return new Promise((resolve, reject) => {
        // Load the reCAPTCHA script
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${captcha_key}`;
        script.async = true;
        script.onload = () => {
            window.grecaptcha.ready(() => {
            window.grecaptcha.execute(captcha_key, { action: 'submit' })
                .then(token => {
                resolve(token);
                })
                .catch(err => {
                reject(err);
                });
            });
        };
        document.body.appendChild(script);
        });
    };

    const handleOnActive = () => {
        console.log("User is active.");

        // Reload the page if the API call was already made once
        if (hasCalledApi) {
            console.log("Reloading page since API call was already made once.");
            window.location.reload();
        }

        // Clear the timeout if the user becomes active
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
    };

    const handleHomeRedirect = () => {
        const newUrl = `/?${queryParams?.toString()}`
        navigate(newUrl);
    }

    return (
        <IdleTimerProvider
            ref={idleTimerRef}
            timeout={1000} // 1 minute timeout
            onIdle={handleOnIdle}
            onActive={handleOnActive}
        >
            <div>
                {/* <RecaptchaComponent onVerify={setRecaptchaToken} path={"Profile"} /> */}
                <div className='pt-[4.2rem] max-sm:px-3 bg-[#282727]'>
                    <div className='lg:max-w-7xl mx-auto relative flex justify-center items-center pb-4 pt-12 max-sm:pt-12 max-sm:pb-4 '>
                        <div className='absolute left-[10%] cursor-pointer max-sm:hidden flex justify-center items-center'>
                            <ArrowLeftCircleFill onClick={handleHomeRedirect} className='text-[#fff] text-3xl' />
                        </div>
                        <div className='flex items-center max-sm:justify-between gap-12 max-sm:gap-5'>
                            <div className='max-sm:flex cursor-pointer hidden justify-center items-center'>
                                <ArrowLeftCircleFill onClick={handleHomeRedirect} className='text-[#fff] text-[38px]' />
                            </div>
                            <div>
                                <p className='text-[#fff] text-3xl max-sm:text-[26px] mb-1 font-[TATAAIARegularPrint] not-italic capitalize font-bold leading-[normal]'>{profile_data?.microsite?.name}</p>
                                <p className='text-[#fff] text-lg max-sm:text-sm not-italic font-[TATAAIAPrint] capitalize font-medium leading-[130%]'>{profile_data?.microsite?.designation}</p>
                            </div>
                            <div onClick={() => { setModalIsOpen(true) }} className='bg-[#D62049] p-2 rounded-full cursor-pointer'>
                                <ShareFill className='w-[20px] max-sm:w-[20px] text-[#fff] h-auto bg-transparent' />
                            </div>
                        </div>
                    </div>
                </div>

                {profile_data?.profile_images?.data?.length > 0 && <div className='h-[70vh] max-sm:h-[25vh]'>
                    <DetailProfile detail={profile_data?.profile_images} />
                </div>}

                <div className='bg-white space-y-16 max-sm:space-y-8 py-12 max-sm:pt-8 lg:max-w-7xl mx-auto'>
                    {profile_data?.partner_achivements?.data?.length > 0 && <div className='w-[80%] max-sm:w-full flex justify-center mx-auto'>
                        <DetailCircle profileData={profile_data?.partner_achivements?.data} />
                    </div>}

                    <div className='w-[80%] max-sm:w-[90%] mx-auto'>
                        <About aboutData={profile_data?.microsite?.about} nps={profile_data?.nps} />
                    </div>
                    
                    {profile_type == "leader" && <>
                        <div className='w-[80%] max-sm:w-[90%] mx-auto'>
                            <JoinTeam cardData={profile_data?.my_team}  />
                        </div>

                        <div className='w-[80%] max-sm:w-[90%] mx-auto'>
                            <JoinAdvisor title="Do you want to join my team as an advisors" subTitle="" buttonTxt="Join As Financial Advisor" />
                        </div>
                    </>}
                    {profile_data?.achivements?.data?.length > 0 && <div className='w-[80%] max-sm:w-[90%] mx-auto'>
                        <h3 className='text-[#444] font-[TATAAIARegularPrint] text-3xl max-sm:text-center max-sm:mb-4 mb-0 max-sm:text-2xl not-italic font-bold leading-[normal]'>Accomplishments</h3>
                        {/* <Accomplishment achievementData={profile_data?.achivements?.data} /> */}
                        <CommonCarousel type="achievement" cardData={profile_data?.achivements?.data} />
                    </div>}

                    {profile_data?.conventions?.data?.length > 0 && <div className='w-[80%] max-sm:w-[90%] mx-auto'>
                        <h3 className='text-[#444] font-[TATAAIARegularPrint] text-3xl max-sm:text-center max-sm:mb-4 mb-6 max-sm:text-2xl not-italic font-bold leading-[normal]'>Conventions attended</h3>
                        {/* <Conventions conventionData={ profile_data?.conventions?.data } /> */}
                        <CommonCarousel type="convention" cardData={profile_data?.conventions?.data} />
                    </div>}

                    {profile_data?.certificate?.data?.length > 0 && <div className='w-[80%] max-sm:w-[90%] mx-auto'>
                        <h3 className='text-[#444] text-3xl font-[TATAAIARegularPrint] max-sm:text-center max-sm:mb-4 mb-12 max-sm:text-2xl not-italic font-bold leading-[normal]'>Trainings & Certifications</h3>
                        <Certification certificateData={profile_data?.certificate?.data} />
                    </div>}

                    {profile_data?.testimonials?.data?.length > 0 && <div className='w-[80%] max-sm:w-[90%] mx-auto'>
                        <p className='text-[#444] text-3xl font-[TATAAIARegularPrint] max-sm:text-center max-sm:mb-4 max-sm:text-2xl not-italic font-bold leading-[normal]'>What my clients say</p>
                        <ClientSwiper review={profile_data?.testimonials?.data} />
                    </div>}
                </div>
                <ShareModal modalIsOpen={modalIsOpen} handleClose={handleClose} url={window.location?.origin + window.location?.pathname} />
            </div>
        </IdleTimerProvider>
    );
};

export default LeaderContainer;
