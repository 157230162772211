import axios from "axios";
import storeApis from "../../api/storeApi";
import Cookies from "js-cookie";
import * as types from "../../constants/MicrositeActionTypes";
import toast from 'react-hot-toast';

export const setMicrositeUnauthorized = (value) => {
    return (dispatch) => {
        dispatch({ type: types.UNAUTHORIZED_MICROSITE, payload: value });
    };
};

export const urlUpdateHandle = (value) => {
    return (dispatch) => {
        dispatch({ type: types.URL_DATA, payload: value });
    };
};

export const saveAuthtoken = (value) => {
    return (dispatch) => {
        dispatch({ type: types.AUTH_TOKEN, payload: value });
    };
};

export const setSystemIp = (value) => {
    return (dispatch) => {
        dispatch({ type: types.SYSTEM_IP, payload: value });
    };
};

export const initializeSolutionData = (value) => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_SOLUTION_TYPE_DATA, payload: value });
    };
};

// Fetch chat history listing

export const fetchMainData = (authToken,url) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: false });
    try {
      const response = await storeApis.post(
        "/framework/api/microsite/loadContent",
        {
          url: url
        },
        {
          headers: {
            Accept: "application/json",
            Vtoken: `${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
        //   dispatch({
        //     type: "UNAUTHORIZED_CHAT_AI",
        //     payload: response.data.statusCode,
        //   });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
          // Toast(response.data.message, "E");
        } else {
          await dispatch({
            type: types.FETCH_MAIN_DATA,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
          // Toast(response.data.message, "S");
        }
      }
    } catch (err) {
    //   dispatch({ type: "TOGGLE_LOADING", payload: false });
    //   dispatch({
    //     type: "UNAUTHORIZED_CHAT_AI",
    //     payload: err.response.status,
    //   });
    }
  };
};

export const fetchLeaderDetailData = (authToken, url) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: false });
    try {
      const response = await storeApis.post(
        "/framework/api/microsite/getProfileDetails",
        {
            type:"full_profile",
            url: url
        },
        {
          headers: {
            Accept: "application/json",
            Vtoken: `${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
        //   dispatch({
        //     type: "UNAUTHORIZED_CHAT_AI",
        //     payload: response.data.statusCode,
        //   });
        //   dispatch({ type: "TOGGLE_LOADING", payload: false });
          // Toast(response.data.message, "E");
        } else {
          await dispatch({
              type: types.FETCH_PROFILE_DATA,
              payload: response.data.response,
            });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
        }
      }
    } catch (err) {
    //   dispatch({ type: "TOGGLE_LOADING", payload: false });
    //   dispatch({
    //     type: "UNAUTHORIZED_CHAT_AI",
    //     payload: err.response.status,
    //   });
    }
  };
};

export const submitLeadForm = (authToken, payload, source, flow, onSuccess) => {   
  payload.source = source;
  payload.flow = flow;
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        "/framework/api/microsite/microSiteLeadForm",
        payload,
        {
          headers: {
            Accept: "application/json",
            Vtoken: `${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
        //   dispatch({
        //     type: "UNAUTHORIZED_CHAT_AI",
        //     payload: response.data.statusCode,
        //   });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
          // Toast(response.data.message, "E");
        } else {
            onSuccess();
            let data = Cookies.get("c");
            if (data != response?.data?.contact_id && response?.data?.contact_id != "" ) {
              Cookies.set("c", response?.data?.contact_id);
            }
            toast.success(response.data.message)
            dispatch({ type: "TOGGLE_LOADING", payload: false });
        }
      }
    } catch (err) {
      dispatch({ type: "TOGGLE_LOADING", payload: false });
    //   dispatch({
    //     type: "UNAUTHORIZED_CHAT_AI",
    //     payload: err.response.status,
    //   });
    }
  };
};


export const fetchQualificationData = (authToken) => {   
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: false });
    try {
      const response = await storeApis.post(
        "/framework/api/microsite/qualifications",
        {},
        {
          headers: {
            Accept: "application/json",
            Vtoken: `${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
        //   dispatch({
        //     type: "UNAUTHORIZED_CHAT_AI",
        //     payload: response.data.statusCode,
        //   });
        //   dispatch({ type: "TOGGLE_LOADING", payload: false });
          // Toast(response.data.message, "E");
        } else {
            dispatch({ type: "QUALIFICATION_DATA", payload: response?.data?.response });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
        }
      }
    } catch (err) {
    //   dispatch({ type: "TOGGLE_LOADING", payload: false });
    //   dispatch({
    //     type: "UNAUTHORIZED_CHAT_AI",
    //     payload: err.response.status,
    //   });
    }
  };
};

export const fetchSolutionTypeData = (authToken, id, onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: false });
    try {
      const response = await storeApis.post(
        "/framework/api/microsite/getCategoryData",
        {},
        {
          headers: {
            Accept: "application/json",
            Vtoken: `${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
        //   dispatch({
        //     type: "UNAUTHORIZED_CHAT_AI",
        //     payload: response.data.statusCode,
        //   });
        //   dispatch({ type: "TOGGLE_LOADING", payload: false });
          // Toast(response.data.message, "E");
        } else {
          await dispatch({
              type: types.FETCH_SOLUTION_TYPE_DATA,
              payload: response.data.response,
          });
          onSuccess();
          dispatch({ type: "TOGGLE_LOADING", payload: false });
        }
      }
    } catch (err) {
    //   dispatch({ type: "TOGGLE_LOADING", payload: false });
    //   dispatch({
    //     type: "UNAUTHORIZED_CHAT_AI",
    //     payload: err.response.status,
    //   });
    }
  };
};

// event tracking

export const eventTracking = (authToken, recaptchaToken, url, action, start_time, end_time, flow, contact_id) => { 
  let cookie_check = Cookies.get("s_t")
  if (action == "start") {
    if (!cookie_check) {
      Cookies.set("s_t", start_time);
    }
  } else {
      Cookies.remove('s_t');
  }
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: false });
    try {
      const response = await storeApis.post(
        "/framework/api/microsite/event-action",
        {
            recaptcha_token: recaptchaToken,
            url: url,
            start: start_time,
            end: end_time,
            idle: action == "idle" ? 600 : 0,
            visit_page: flow,
            c: contact_id,
        },
        {
          headers: {
            Accept: "application/json",
            Vtoken: `${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
        //   dispatch({
        //     type: "UNAUTHORIZED_CHAT_AI",
        //     payload: response.data.statusCode,
        //   });
        //   dispatch({ type: "TOGGLE_LOADING", payload: false });
          // Toast(response.data.message, "E");
        } else {
          dispatch({ type: "TOGGLE_LOADING", payload: false });
        }
      }
    } catch (err) {
    //   dispatch({ type: "TOGGLE_LOADING", payload: false });
    //   dispatch({
    //     type: "UNAUTHORIZED_CHAT_AI",
    //     payload: err.response.status,
    //   });
    }
  };
};